import React, { useState, useEffect } from "react";
import questionsData from "./questions.json";
// import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import Popup from "./Popup.jsx";

// Reusable QuestionCard Component
const QuestionCard = ({
  question,
  options,
  currentQuestion,
  setCurrentQuestion,
  handleSubmit,
  selectedOption,
  handleOptionChange,
  totalQuestions,
}) => {
  const handleNext = () => {
    if (currentQuestion < totalQuestions - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const isLastQuestion = currentQuestion === totalQuestions - 1;

  return (
    <div className="w-full p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">
        Q.{currentQuestion + 1} {question}
      </h2>
      <div className="space-y-4">
        {options.map((option, index) => (
          <div key={index} className="flex items-center">
            <input
              type="radio"
              id={`option-${index}`}
              name="option"
              value={option}
              checked={selectedOption === option}
              onChange={() => handleOptionChange(option)}
              className="mr-2"
            />
            <label htmlFor={`option-${index}`} className="text-lg">
              {option}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-between">
        <button
          onClick={handlePrev}
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Previous
        </button>

        {/* Show Submit button for the last question */}
        {isLastQuestion ? (
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white py-2 px-4 rounded-md"
          >
            Submit
          </button>
        ) : (
          <button
            onClick={handleNext}
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};


// Main Test Component
const Test = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes = 1800 seconds
  const [userAnswers, setUserAnswers] = useState({});
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [certified, setCertified] = useState(false);
  const [username, setUsername] = useState("");
  const [courseName, setCourseName] = useState("");
  const [testType, setTestType] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getQuestionsForTestType = (testType) => {
    switch (testType) {
      case "Beginner":
        return questionsData["Beginner"];
      case "Intermediate":
        return questionsData["Intermediate"];
      case "Advanced":
        return questionsData["Advanced"];
      default:
        return [];
    }
  };

  const currentQuestions = getQuestionsForTestType(testType);
  const currentData = currentQuestions[currentQuestion];

  const handleOptionChange = (option) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: option,
    }));
  };

  const handleSubmit = () => {
    setQuizSubmitted(true);
    const score = calculateScore();
    if (score >= 30) {
      setCertified(true);
    }
    setShowPopup(true);
  };

  const calculateScore = () => {
    let score = 0;
    currentQuestions.forEach((question, index) => {
      if (userAnswers[index] === question.answer) {
        score += 1;
      }
    });
    return score;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isAnswered = (index) => {
    return userAnswers[index] !== undefined;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0 && !quizSubmitted) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        clearInterval(timer);
        if (!quizSubmitted) handleSubmit(); // Submit quiz automatically when time runs out
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, quizSubmitted]);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (username && courseName && testType) {
      setIsFormSubmitted(true);
    } else {
      alert("Please fill in all fields.");
    }
  };

  if (!isFormSubmitted) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-200">
        <form
          onSubmit={handleFormSubmit}
          className="bg-white p-7 rounded-lg shadow-md w-80"
        >
          <h2 className="text-2xl font-semibold mb-6">Start Quiz</h2>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter your name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2">
              Course Name
            </label>
            <select
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Course</option>
              <option value="CompTIA">CompTIA</option>
              <option value="Micresoft">Micresoft</option>
              <option value="PCEB">PCEB</option>
              <option value="AWS">AWS</option>
            </select>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-semibold mb-2">
              Select Test Type
            </label>
            <select
              value={testType}
              onChange={(e) => setTestType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Test Type</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-md"
          >
            Take the Quiz
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:hidden p-4 bg-gray-800">
        <button onClick={toggleSidebar} className="text-2xl text-white">
          {isSidebarOpen ? <IoMdClose /> : <IoMenu />}
        </button>
      </div>

      <div
        className={`md:w-1/4 bg-gray-800 text-white p-4 md:block ${
          isSidebarOpen ? "block" : "hidden md:block"
        }`}
      >
        <h1 className="text-2xl font-bold mb-8">Questions</h1>
        <div className="space-y-2 grid grid-cols-5 gap-2">
          {currentQuestions.map((question, index) => (
            <div key={question.id} className="cursor-pointer mt-2">
              <button
                onClick={() => setCurrentQuestion(index)}
                className={`w-12 h-12 text-center rounded-md ${
                  currentQuestion === index
                    ? "bg-blue-500"
                    : isAnswered(index)
                    ? "bg-green-500"
                    : "bg-gray-700"
                }`}
              >
                {question.id}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-1 p-8">
        <div className="mb-6 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-semibold">Username: {username}</h2>
            <h3 className="text-lg">Course: {courseName}</h3>
            <h4 className="text-base">Type: {testType}</h4>
          </div>
          <div className="flex items-center">
            <span className="text-lg font-semibold mr-2">
              Time Left: {Math.floor(timeLeft / 60)}:
              {String(timeLeft % 60).padStart(2, "0")}
            </span>
          </div>
        </div>

        {quizSubmitted ? (
          ""
        ) : currentData ? (
          <QuestionCard
            question={currentData.question}
            options={currentData.options}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            handleSubmit={handleSubmit}
            selectedOption={userAnswers[currentQuestion]}
            handleOptionChange={handleOptionChange}
            totalQuestions={currentQuestions.length}
          />
        ) : (
          <p>No questions available</p>
        )}
      </div>

      {showPopup && (
        <Popup
          score={calculateScore()}
          totalQuestions={currentQuestions.length}
          username={username}
          courseName={courseName}
          onClose={closePopup}
          onCertify={() => alert("You have received your certification!")}
        />
      )}
    </div>
  );
};

export default Test;