import React from "react";
import { IoMdClose } from "react-icons/io";
import Lottie from "lottie-react";
import Certificate from "../../assets/certificate.json";

const Popup = ({ score, totalQuestions, onClose, onCertify }) => {
  const isEligibleForCertification = score >= 10; // Number of questions a user should achieve to get a certificate

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-96 w-72 text-center relative">
        {/* Close Button in Top Right Corner */}
        <button
          onClick={onClose}
          className="absolute top-4 right-5 text-xl text-gray-600 hover:text-gray-800"
        >
          <IoMdClose />
        </button>

        <h2 className="text-4xl font-semibold mb-4">Quiz Results</h2>
        <p className="text-xl font-bold">
          Your score: {score} / {totalQuestions}
        </p>
        <p
          className={`${
            isEligibleForCertification ? "text-green-500" : "text-red-500"
          } mt-4`}
        >
          {isEligibleForCertification
            ? "Congratulations! You have passed the quiz."
            : "Better luck next time."}
        </p>

        {isEligibleForCertification && (
          <div>
            <Lottie animationData={Certificate} loop={true} />
            <button
              onClick={onCertify}
              className="mt-4 bg-green-500 text-white py-2 px-4 rounded-md"
            >
              Get Certification
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;